/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from 'environments/environment';
import { InjectionToken, enableProdMode } from '@angular/core';
import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { ApiResult } from 'app/core/models/api-result';
import { ConfigAuthOutputDto } from '@shared/api/model/config-auth-output-dto';
import { lastValueFrom, map, share } from 'rxjs';

const apiUrl = environment.dataIntegratorApiUrl + "/api/di/v1";
const http = new HttpClient (new HttpXhrBackend({build: () => new XMLHttpRequest()}));

export let authConfig: ConfigAuthOutputDto;

if (environment.production) {
  enableProdMode();
}

const span = document.createElement('span');
span.className = 'pi pi-spin pi-spinner text-6xl';

const card = document.createElement('div');
card.append(span);

const container = document.createElement('div');
container.className = 'flex justify-content-center align-items-center w-full h-full';

container.append(card);

const appRoot = document.getElementsByTagName('app-root')[0];
appRoot.prepend(container);


lastValueFrom(http.get<ApiResult<ConfigAuthOutputDto>>(`${apiUrl}/auth/config`))
.then(res => {
  authConfig = res.data as ConfigAuthOutputDto;
  appRoot.removeChild(container);

  platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
})
.catch(() => {
  card.className = 'p-card py-6 px-6';
  span.className = 'text-2xl';
  span.textContent = "An error occured while setting up the app.";
});

<app-auth>
  <app-loader [loading]="loading">
    <p-confirmDialog></p-confirmDialog>
    <div class="h-full">
      <header class="navbar-container">
        <app-navbar [mobileItems]="menuItems" [userItems]="userItems"></app-navbar>
      </header>
      <aside class="hidden md:block sidepanel-container">
        <app-sidepanel [actualRoute]="actualRoute" [disableRoutes]="['/', '/home']"
          [items]="menuItems"></app-sidepanel>
      </aside>
      <main class="content-container">
        <div class="py-4">
          <router-outlet></router-outlet>
        </div>
      </main>
      <footer class="footer-container">
        <app-footer></app-footer>
      </footer>
    </div>
  </app-loader>

  <p-toast></p-toast>
</app-auth>
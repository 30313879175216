import { Injectable, OnInit, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest, filter, firstValueFrom, map, of, skipUntil, skipWhile, switchMap, take, takeWhile, tap } from 'rxjs';
import { AuthFacade } from '../auth.facade';
import { Role } from '../models/role';
import { AuthAccessUtils } from '../utils/auth-access-utils';
import { AuthAccess } from '../models/auth-access';
import { AuthAccessLogicalType } from '../models/auth-access-logical-type';
import { User } from '../models/user';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private authFacade: AuthFacade, private router: Router, private authAccessUtils: AuthAccessUtils) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.authFacade.user$.pipe(
      skipWhile(user => {
        if (user && user.roles && user.policies) {
          return false;
        } else {
          return true;
        }
      }), // Wait while not loaded roles
      take(1),
      map((user) => {

        if (!user)
          return false;

        let authAccess: AuthAccess[] = route.data["access"] ? route.data["access"] as AuthAccess[] : [];
        let accessType: AuthAccessLogicalType = route.data["type"] ? route.data["type"] as AuthAccessLogicalType : AuthAccessLogicalType.Or;

        if (!this.authAccessUtils.evaluationOfAuths(user.policies!, user.roles!, authAccess, accessType)) {
          this.router.navigate(["/error/401"]);
          return false;
        }

        return true;
      }))
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  return inject(AuthGuardService).canActivate(next, state);
}

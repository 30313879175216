import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { selectAppError, selectCoreState, selectUser } from "./state/core.selector";
import { ShowError } from "./containers/show-error/models/show-error";
import { MenuService } from "./services/menu.service";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { AuthService } from "app/auth/services/auth.service";
import { AuthState } from "app/auth/state/auth.reducer";
import { selectAuthIsAuth } from "app/auth/state/auth.selector";
import { HttpClient } from "@angular/common/http";
import { CoreActions, CoreApiActions } from "./state/core.action";
import { CoreState } from "./state/core.reducer";
import { AuthFacade } from "app/auth/auth.facade";
import { Subscription } from 'rxjs';
import { MenuItemDto } from "./models/menu-item-dto";
import { MenuItem } from "primeng/api";


@Injectable()
export class CoreFacade implements OnDestroy {
    error$ = this.store.select(selectAppError);
    user$ = this.store.select(selectUser);
    menuItems$: Observable<MenuItem[]> = of([]);
    userItems$: Observable<MenuItem[]> = of([]);

    private subscriptions: Subscription[] = [];

    constructor(private store: Store<CoreState>, private menuService: MenuService, private http: HttpClient, private authFacade: AuthFacade) { }

    // Init store
    init() {
        this.store.dispatch(CoreActions.init());
    }

    // Load menu items
    loadMenuItems() {
        this.store.dispatch(CoreApiActions.loadMenuItems());
        this.menuItems$ = this.menuService.getMenuItems();
        this.userItems$ = this.menuService.getUserItems();
        this.store.dispatch(CoreApiActions.loadMenuItemsSuccess());
    }

    // Get current user
    getCurrentUser() {
        //return this.user$.subscribe( x => x?.name);
        this.subscriptions.push(
            this.user$.subscribe(x => {return x?.name})
        );
    }

    ngOnDestroy(): void {    
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
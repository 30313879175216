import { Injectable } from '@angular/core';
import { Observable, concatMap, map, of, skipWhile, switchMap, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { AuthFacade } from 'app/auth/auth.facade';
import { Role } from 'app/auth/models/role';
import { User } from 'app/auth/models/user';
import { MenuItemDto } from '../models/menu-item-dto';
import { menuItems, userItems } from '../data/menuItems';
import { AuthAccessUtils } from 'app/auth/utils/auth-access-utils';
import { AuthAccess } from 'app/auth/models/auth-access';
import { Policy } from 'app/auth/models/policy';
import { AuthAccessLogicalType } from 'app/auth/models/auth-access-logical-type';
import { MenuItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private menuItems: Observable<MenuItem[]>;
  private userItems: Observable<MenuItem[]>;
  // private filename: string = $localize`menuItems.json`;

  constructor(private authFacade: AuthFacade, private authAccessUtils: AuthAccessUtils) {
    // let fullpath = "../data/" + this.filename;
    // this.menuItems = this.http.get<MenuItemDto[]>(fullpath);
    this.menuItems = of(menuItems);
    this.userItems = of(userItems);
  }

  getMenuItems(): Observable<MenuItem[]> {
    return this.authFacade.user$.pipe(
      skipWhile(user => {
        if (user && user.roles && user.policies) {
          return false;
        } else {
          return true;
        }
      }),
      take(1),
      switchMap(user => {
        return this.menuItems.pipe(
          switchMap(menuItems => {
            return of(this.findMenuItemsByRole(menuItems, user!.policies ?? [], user!.roles ?? []));
          })
        )
      })
    )
  }

  
  getUserItems(): Observable<MenuItem[]> {
    return this.authFacade.user$.pipe(
      skipWhile(user => {
        if (user && user.roles && user.policies) {
          return false;
        } else {
          return true;
        }
      }),
      take(1),
      switchMap(user => {
        return this.userItems.pipe(
          switchMap(userItems => {
            return of(this.findMenuItemsByRole(userItems, user!.policies ?? [], user!.roles ?? []));
          })
        )
      })
    )
  }

  // Validate menu items and child menu items if i can show it 
  private findMenuItemsByRole(menuItems: MenuItem[], policies: Policy[], roles: Role[], authAccessLogicalType: AuthAccessLogicalType = AuthAccessLogicalType.Or) : MenuItem[] {
    let resultMenuItems: MenuItem[] = [];
    for (let menuItem of menuItems) {
      if (menuItem['authAccess'] && menuItem['authAccess'].length > 0) {
        if (this.authAccessUtils.evaluationOfAuths(policies, roles, menuItem['authAccess'], authAccessLogicalType)) {
          if(menuItem.items && menuItem.items.length > 0) {
            menuItem.items = this.findMenuItemsByRole(menuItem.items, policies, roles);
          }
          resultMenuItems.push(menuItem);
        }
      } else if (!menuItem['authAccess'] || menuItem['authAccess'].length == 0) {
        if(menuItem.items && menuItem.items.length > 0) {
          menuItem.items = this.findMenuItemsByRole(menuItem.items, policies, roles);
        }
        resultMenuItems.push(menuItem);
      }
    }

    return resultMenuItems
  }
}

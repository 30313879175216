import { InjectionToken } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BrowserCacheLocation, Configuration, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { Policy } from '../models/policy';
import { Group } from '../models/group';
import { PolicyType } from '../models/policy-type';
import { group } from '@angular/animations';
import { ConfigAuthOutputDto } from '@shared/api/model/config-auth-output-dto';
import { authConfig } from 'main';

export const msalConfig: Configuration = {
    auth: {
        clientId: undefined!
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false, // set to true for IE 11
    },
    system: {
        loggerOptions: {
            loggerCallback: (logLevel: any, message: any) => {
                // console.log(message);
            },
            piiLoggingEnabled: false,
            logLevel: LogLevel.Info
        }
    }
    // interceptor: {
    //     interactionType: InteractionType.Redirect,
    //     protectedResourceMap: [
    //         ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    //         ['https://graph.microsoft.com/v1.0/groups/', ['Group.Read.All']],
    //         // the custom scope must be defined in Azure AD under 'Expose an API' - it triggers the Auth token
    //         ['/api/', ['api://41e45c14-0d23-46eb-8e84-4cf578a83f5c/TEST']] // automatically insert the Auth token
    //     ]
    // }
}

export function MSALInstanceFactory(): IPublicClientApplication {
    msalConfig.auth.authority = authConfig.authority!;
    msalConfig.auth.clientId = authConfig.clientId!;
    msalConfig.auth.redirectUri = authConfig.redirectUrl!;
    msalConfig.auth.postLogoutRedirectUri = authConfig.redirectUrl;
    return new PublicClientApplication(msalConfig);
}


export function MsalGuardConfigurationFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
    };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(authConfig.graphUrl + '/me/memberOf', ['user.read']);
    protectedResourceMap.set(environment.dataIntegratorApiUrl + "/api/di/v1/", [authConfig.scope!]);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function CreatePolicyConfiguration(groups: Group[]): Policy[] {
    let policies: Policy[] = new Array();

    // for adding needed policies

    return policies;
}


export const loginRequest = {
    scopes: [],
};